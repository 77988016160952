export enum MaterialitySteps {
  MATERIAL_TOPICS = 0,
  IMPACT_ON_BUSINESS_SURVEY = 1,
  IMPORTANCE_TO_STAKEHOLDER_SURVEY = 2,
  SURVEY_RESULTS = 3,
  FINALIZATION = 4,
}

export enum SurveyAnswerState {
  NEW = 'NEW',
  ENDED = 'ENDED',
  COMPLETE = 'COMPLETE',
}

export enum MaterialityTopicUpdateType {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}
